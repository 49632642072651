<template>
  <UiPopup
    :model-value="modelValue"
    title="Set a reminder"
    description="Please indicate the reason why you are moving the lead to “Future Buyer” state and add a follow-up reminder."
    primary-button-text="Move to Future Buyer"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <form ref="form" @submit.prevent>
      <UiInputDatePicker v-model="data.awake_at" name="awake_at" placeholder="Reminder date" class="mb-4" />
      <UiInputSelect
        v-model="data.pipeline_step_id"
        :items="reasonsItems"
        name="Reason"
        placeholder="Choose a reason"
        class="mb-4"
        :error="v$.pipeline_step_id.$errors[0] ? v$.pipeline_step_id.$errors[0].$message.toString() : ''"
      />
      <UiInputTextField
        v-if="reasonsItems?.find((i) => i.value === data.pipeline_step_id)?.text === 'Other'"
        v-model="data.otherReason"
        name="Other"
        placeholder="Add other reason"
        class="mb-4"
      />
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { Lead, Stage } from '@/types'
import { useUiStore } from '@/store/ui'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
  stage: Stage
}

const uiStore = useUiStore()

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})
const reasonsItems = props.stage.steps?.map((r) => ({ value: r.id, text: r.name }))

const loading = ref(false)
const data = ref({
  awake_at: new Date(),
  pipeline_step_id: null,
  otherReason: '',
})

const rules = computed(() => ({
  awake_at: { required: helpers.withMessage('Reminder date is required', required) },
  pipeline_step_id: { required: helpers.withMessage('The reason is required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    loading.value = true
    const payload = {
      pipeline_stage_id: props.stage?.id,
      pipeline_step_id: data.value.pipeline_step_id,
      awake_at: data.value.awake_at,
    }
    if (data.value.otherReason) payload.comment = data.value.otherReason
    try {
      const lead = await useMoveLeadToDormant(props.lead.id, payload)
      uiStore.showSnackBanner(`${props.lead.name} was moved to Future Buyer`)

      emits('input', lead)
      emits('update:modelValue', false)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
